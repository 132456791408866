import Vue from 'vue'
import Router from 'vue-router'
import login from '@/components/login'
import liulan from '@/components/liulan'
import classstyle from '@/components/classstyle'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/login' },
        { path: '/login', component: login },
        { path: '/liulan', component: liulan },
        { path: '/classstyle', component: classstyle },
    ]
})

export default router