<template>
  <div class="divone">
        <p class="loginspan">登录界面</p>
        <div class="inputBox">
            <input type="text" required="required" id="inputone">
            <span>User Name</span>
        </div>
        <div class="inputBox">
            <input type="password" required="requried" id="inputtwo">
            <span>The Code</span>
        </div>
        <button id="loginbutton" @click="to360()">登录</button>
        <div style="text-align: center;" class="div-bo-ttom"><a href="https://beian.miit.gov.cn/#/Integrated/index">
            <p>浙ICP备2022019711号-1</p>
        </a></div>
  </div>
</template>
<script>
    import axios from "axios";
    import qs from "qs";
    export default {
        methods: {
            to360() {
                const that = this;
                let a = document.getElementById('inputone').value;
                let b = document.getElementById('inputtwo').value;
                if (a === '2422861289' && b === 'zengweiwei0412.') {
                    this.$router.push('/liulan');
                } else {
                    alert("用户名或密码错误");
                }
                // axios.post("http://localhost:8080/users/login",
                //         // 数据qs序列化
                //         qs.stringify({
                //             username: a,
                //             password: b,
                //         })
                //     )
                //     .then(function(res) {
                //         alert("注册成功");
                //         that.$router.push('/classstyle');
                //         console.log(res);
                //     })
                //     .catch(function(err) {
                //         console.log(err);
                //     });
            }
        }
    }
</script>

<style scoped>
    @import url(../assets/css/logincs.css);
</style>