import { render, staticRenderFns } from "./liulan.vue?vue&type=template&id=9063a372&scoped=true&"
import script from "./liulan.vue?vue&type=script&lang=js&"
export * from "./liulan.vue?vue&type=script&lang=js&"
import style0 from "./liulan.vue?vue&type=style&index=0&id=9063a372&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9063a372",
  null
  
)

export default component.exports