<template>
  <div class="allsituation">
    <div class="item-container">
      <div class="item-logo">
        <a id="imgLogo2022" target="_self" title="360导航,新一代安全上网导航" href="https://hao.360.com/">
          <img src="https://hao2.qhimg.com/t01b27bb76e133d0ee1.png" alt="图片无法显示">
        </a>
      </div>
      <div class="weather">
        <div class="weather-inner">
          <div class="local-container">
           <p class="theme-text">
            <a href="https://www.so.com/s?ie=utf-8&q=%E6%B5%99%E6%B1%9F%E4%B9%89%E4%B9%8C%E5%A4%A9%E6%B0%94%E9%A2%84%E6%8A%A5&src=hao_weather_mob">义乌</a>
           </p>
           <a id="weather-change" href="https://www.so.com/s?ie=utf-8&q=%E6%B5%99%E6%B1%9F%E4%B9%89%E4%B9%8C%E5%A4%A9%E6%B0%94%E9%A2%84%E6%8A%A5&src=hao_weather_mob">[切换]</a>
          </div>
          <div class="weathertody">
            <a id="weather-detail" href="https://www.so.com/s?ie=utf-8&q=%E6%B5%99%E6%B1%9F%E4%B9%89%E4%B9%8C%E5%A4%A9%E6%B0%94%E9%A2%84%E6%8A%A5&src=hao_weather" target="_blank">
              <span class="weather-icon"></span>
              <div class="degree">
                <span class="">21&nbsp;~&nbsp;25°C</span>
              </div>
              <div class="status">
                <span class="">今&nbsp;&nbsp;&nbsp;&nbsp;大雨</span>
              </div>
            </a>
            <div class="extend-div">
              <a href="http://www.so.com/s?ie=utf-8&src=hao_360weather&q=%E4%B9%89%E4%B9%8Cpm2.5" title="今日pm2.5指数为30，空气质量优，可以尽情享受清新空气">
                <em>优</em>
              </a>
            </div>
          </div>
          <div class="weathertomorrow">
            <a class="weather-detailtwo" href="https://www.so.com/s?ie=utf-8&q=%E6%B5%99%E6%B1%9F%E4%B9%89%E4%B9%8C%E5%A4%A9%E6%B0%94%E9%A2%84%E6%8A%A5&src=hao_weather" target="_blank">
              <span class="weather-icontwo"></span>
              <div class="degreetwo">
                <span class="">21&nbsp;~&nbsp;28°C</span>
              </div>
              <div class="statustwo">
                <span class="">明&nbsp;&nbsp;&nbsp;&nbsp;小雨</span>
              </div>
            </a>
          </div>
          <div class="weather-more">
            <a href="https://www.so.com/s?ie=utf-8&q=%E6%B5%99%E6%B1%9F%E4%B9%89%E4%B9%8C%E5%A4%A9%E6%B0%94%E9%A2%84%E6%8A%A5&src=hao_weather_mob" target="_blank">
              <span class="extend-arrows">>></span>
            </a>
          </div>
        </div>
      </div>
      <span class="topbar-splitter">丨</span>
    <div class="div-date">
      <div class="div-dateone">
        <a href="https://hao.360.com/rili/" target="_blank">9月14日</a>
        <a href="https://hao.360.com/rili/" target="_blank">八月十九</a>
      </div>
      <div class="div-datetwo">
        <a href="https://hao.360.com/rili/" target="_blank">星期三</a>
        <a href="https://www.so.com/s?ie=utf-8&src=hao_360so_b_cube&shb=1&q=%E7%96%AB%E6%83%85" target="_blank">疫情动态</a>
      </div>
    </div>
    <span class="topbar-splittertwo">丨</span>
    <div class="div-tools">
      <div class="div-toolsone">
        <a href="https://young.hao.360.com/?src=index">切换少年版</a>
      </div>
      <div class="div-toolstwo">
        <a href="https://www.so.com/s?q=%E7%81%AB%E8%BD%A6%E7%A5%A8%E6%9F%A5%E8%AF%A2&src=hao_360so_ticket" target="_blank">查车票</a>
      </div>
    </div>
    <span class="topbar-splitterthird">丨</span>
    <div class="div-toolbar">
      <div class="div-toolbarone">
        <a href="https://feedback.hao.360.cn/" target="_blank">反馈</a>
        <a href="https://www.wenjuan.com/s/jUvUzuC/?group=%E5%8F%B3%E4%B8%8A%E8%A7%92%E6%BB%9A%E5%8A%A8%E8%AF%8D%E6%9D%A1" target="_blank">调研</a>
        <a href="" target="_blank">换肤</a>
      </div>
      <div class="div-toolbartwo">
        <a href="">登录/注册</a>
      </div>
    </div>
    </div>
    <div class="doc-top-bd">
      <div class="div-serch-tab">
        <ul class="div-serch-tab-ul">
          <li>
            <a href="">网页</a>
          </li>
          <li>
            <a href="">咨询</a>
          </li>
          <li>
            <a href="">视频</a>
          </li>
          <li>
            <a href="">图片</a>
          </li>
          <li>
            <a href="">良医</a>
          </li>
          <li>
            <a href="">微博</a>
          </li>
          <li>
            <a href="">地图</a>
          </li>
          <li>
            <a href="">问答</a>
          </li>
          <li>
            <a href="">软件</a>
          </li>
          <li>
            <a href="">问诊</a>
          </li>
          <li>
            <a href="">公益</a>
          </li>
        </ul>
      </div>
      <div id="search-frame">
        <div class="top-div-serch">
          <input class="div-serch" type="text">
        </div>
        <button id="serch-btn" type="submit">搜索</button>
      </div>
      <div class="div-serch-ft">
        <div class="div-serch-ft-ul">
          <a class="set" href="">
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E5%93%94%E5%93%A9%E5%93%94%E5%93%A9&eci=13231508&nlpv=lab622zt45a">
            <img src="https://p0.ssl.qhimg.com/t0194c2350d3de0883d.ico" alt="bilibil" style="width:12px;height:12px">
            <span s>哔哩哔哩</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E8%85%BE%E8%AE%AF%E8%A7%86%E9%A2%91&eci=13186680&nlpv=lab622zt45a">
            <img src="https://p0.ssl.qhimg.com/t01ac78fa7e2e7a7d0f.ico" alt="腾讯视频" style="width:12px;height:12px">
            <span>腾讯视频</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E7%88%B1%E5%A5%87%E8%89%BA%E5%9C%A8%E7%BA%BF%E8%A7%82%E7%9C%8B&eci=&nlpv=lab622zt45a">
            <img src="https://p0.ssl.qhimg.com/t013e77cbd9684c6bfb.ico" alt="爱奇艺在线观看" style="width:12px;height:12px">
            <span>爱奇艺在线观看</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E5%BE%AE%E4%BF%A1%20%E7%94%B5%E8%84%91%E7%89%88%E4%B8%8B%E8%BD%BD&eci=12965484&nlpv=lab622zt45a">
            <span>微信&nbsp电脑版下载</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E5%BF%AB%E9%80%92%E5%8D%95%E5%8F%B7%E6%9F%A5%E8%AF%A2&eci=13186680&nlpv=lab622zt45a">
            <span>快递单号查询</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=%E7%99%BE%E5%BA%A6%E7%BD%91%E7%9B%98&eci=13186680&nlpv=lab622zt45a">
            <span>百度网盘</span>
          </a>
          <a href="https://www.so.com/s?ie=utf-8&src=hao_isearch2_cube&q=4399%E5%B0%8F%E6%B8%B8%E6%88%8F%E5%A4%A7%E5%85%A8&eci=13186680&nlpv=lab622zt45a">
            <img src="https://p0.ssl.qhimg.com/t010fb5a321c9704e03.ico" alt="4399小游戏大全" style="width:12px;height:12px;">
            <span>4399小游戏大全</span>
          </a>
        </div>
      </div>
    </div>
    <div class="dongdong-box">
      <div class="dongdong-gclearfix">
        <div class="dongdong-gclearfixone" style="width:212px;height:36px;margin: 0 8px 0 12px;">
          <img src="//hao1.qhimg.com/t01082aa64d17c3e904.png" alt="生活" style="width:18px;height:18px;">
          <a href="https://www.360.cn/">卫视</a>
          <a href="https://hao.360.com/rili/#cid=youlike">日历</a>
          <a href="https://www.360kuai.com/pc/home?sign=360_b7dbbfe4">新闻</a>
          <a href="https://young.hao.360.com/?src=dongdong">少年版</a>
          <span class="dongdong-box-fengeione">|</span>
        </div>
        <div class="dongdong-gclearfixtwo dongdong-gclearfixone" style="width:212px;height:36px;margin: 0 8px 0 12px;">
          <img src="http://hao1.qhimg.com/t01131a47dc96aaa1cb.png" alt="综艺" style="width:18px;height:18px;">
          <a href="https://yule.360.com/">娱乐</a>
          <a href="https://www.360kan.com/zongyi/index.html?from=dongdong">综艺</a>
          <a href="https://www.360kan.com/dianying/index.html?from=dongdong">电影</a>
          <a href="https://www.360kan.com/dianshi/index.html?from=dongdong">电视剧</a>
          <span class="dongdong-box-fengeione">|</span>
        </div>
        <div class="dongdong-gclearfixthird dongdong-gclearfixone" style="width:212px;height:36px;margin: 0 8px 0 12px;">
          <img src="http://hao1.qhimg.com/t013a8296f7a5989c7e.png" alt="休闲" style="width:18px;height:18px;">
            <a href="https://gouwu.360.com/">购物</a>
            <a href="https://ai.taobao.com/search/index.htm?refpid=mm_26632323_13476194_53296945&key=%E5%A5%B3%E8%A3%85&clk1=903219ad66eeb80e1055dc5df3873850">女装</a>
            <a href="https://g.360.cn/index.html">游戏</a>
            <a href="https://xiaoyouxi.360.cn/?src=youxi">小游戏</a>
            <span class="dongdong-box-fengeithird">|</span>
        </div>
        <div class="dongdong-gclearfixfour dongdong-gclearfixone" style="width:212px;height:36px;margin: 0 8px 0 12px;">
          <img src="http://hao1.qhimg.com/t01b82a8a713a870456.png" alt="财富" style="width:18px;height:18px;">
          <a href="https://licai.360.cn/">理财</a>
          <a href="https://licai.360.cn/#bank">银行</a>
          <a href="https://auto.360.cn/?sign=360_d6d594ba&refer_scene=car2">汽车</a>
          <a href="https://sports.hao.360.cn/#cid=sport?">体育</a>
          <span class="dongdong-box-fengeifour">|</span>
        </div>
        <div class="dongdong-gclearfixfive dongdong-gclearfixone" style="width:212px;height:36px;margin: 0 8px 0 12px;">
          <img src="http://hao1.qhimg.com/t0148fdfb7f05f005ee.png" alt="时事" style="width:18px;height:18px;">
          <a href="https://go.360.cn/">旅游</a>
          <a href="https://junshi.hao.360.cn/#cid=militery?">军事</a>
          <a href="https://fangchan.hao.360.cn/#cid=estate?">房产</a>
          <a href="https://young.hao.360.com/?src=index" class="orange">体验版</a>
        </div>
      </div>
    </div>
    <div class="focus-news">
      <div class="sites-gclearfix">
        <ul class="sites-gclearfix-ul">
          <li>
            <a href="http://www.people.com.cn/" alt="人民网">人民网</a>
          </li>
          <li>
            <a href="http://www.xinhuanet.com/" alt="新华网">新华网</a>
          </li>
          <li>
            <a href="https://www.cctv.com/" alt="央视网">央视网</a>
          </li>
          <li>
            <a href="http://www.china.com.cn/" alt="中国网">中国网</a>
          </li>
          <li>
            <a href="https://www.cri.cn/" alt="国际在线">国际在线</a>
          </li>
          <li>
            <a href="http://cn.chinadaily.com.cn/" alt="中国日报">中国日报</a>
          </li>
          <li>
            <a href="http://www.ce.cn/" alt="中经网">中经网</a>
          </li>
          <li>
            <a href="https://www.gmw.cn/" alt="光明网">光明网</a>
          </li>
          <li>
            <a href="http://www.cnr.cn/" alt="央广网">央广网</a>
          </li>
          <li>
            <a href="http://www.qstheory.cn/" alt="求是网">求是网</a>
          </li>
          <li>
            <a href="https://www.youth.cn/" alt="中青网">中青网</a>
          </li>
          <li>
            <a href="http://www.81.cn/" alt="中国军网">中国军网</a>
          </li>
          <li>
            <a href="http://www.cac.gov.cn/" alt="网信网">网信网</a>
          </li>
          <li>
            <a href="http://www.zjol.com.cn/" alt="浙江在线">浙江在线</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="hot-new-site">
      <div class="hot-size">
        <ul class="hot-size-ul">
          <li>
            <a class="favicon" href="https://www.taobao.com/?spm=a1z02.1.1581860521.1.ilSV2R&ali_trackid=2:mm_26632323_6844412_111155150028:1663836196_001_1922839785&union_lens=lensId:OPT@1612256136@21081cca_07a6_17761f44ebb_baac@01;eventPageId:20150318020003697;recoveryid:1663836196_001_1922839785&clk1=a42af9e78adf76387cdcc30a870f9a04&bxsign=tbk7RrxndccqTh8JCPNppaNRixvwHpkQaS_s2LXNpINrRMEcOs-w93vGp3_6f06HtqJo0ebye36PmAv7sQs_arOzRB9mIzq-tR2XD7cTreXeQubRIgWOaXOMTZDOYPr4Fs8u_WhWW0U4cQZ43hvjwB3ig" alt="淘宝">
              <img src="https://hao1.qhimg.com/t01a4719eed5998ac4b.png" alt="淘宝">
              <span>淘宝</span>
            </a>
            <span>•</span>
            <a href="https://ai.taobao.com/?pid=mm_26632323_6762370_107181450401&clk1=7e405e93cd7576761bbd444b2a8ed15f" alt="特卖">
              <span>特卖</span>
            </a>
          </li>
          <li>
            <a href="">58&nbsp;同&nbsp;城</a>
          </li>
          <li>
            <a class="favicon" href="https://www.tmall.com/?ali_trackid=2:mm_26632323_6762370_25910656:1663839730_003_1809204847&union_lens=lensId:OPT@1615280124@2104be69_0782_1781632b34a_ced8@01;eventPageId:8655681;recoveryid:1663839730_003_1809204847&clk1=b3594ad40297e13e261834a16c398f55&bxsign=tbk4Qf6rSFDi13j5VmEazl3zJnyDLxFfh7ZtlfNzrfT4BPRGTi2-RaiSWZ6vfmlF_WUla5phwr2mROEQ177H0YiNFFpogsgbk8DxrHDT07FCSE2zBgATG5SlsMU_3QocpLIEY7f5ztQJoPVLQAoH9YXAA" alt="天猫">
              <img src="https://hao1.qhimg.com/t013606f16d85780004.gif" alt="天猫">
              <span>天猫</span>
            </a>
            <span>•</span>
            <a href="https://pages.tmall.com/wow/jinkou/act/zhiyingchaoshi?from=zebra:offline" alt="直营">
              <span>直营</span>
            </a>
          </li>
          <li>
            <a class="favicon" href="https://p4psearch.1688.com/hamlet.html?scene=6&cosite=360daohang&location=mingzhan" alt="阿里1688">
              <img src="https://hao1.qhimg.com/t019cee719b7b4a38af.png" alt="阿里1688">
              <span>阿里1688</span>
            </a>
          </li>
          <li>
            <a  class="favicon" href="https://www.ctrip.com/?allianceid=1328&sid=1643" alt="携程旅行">
              <img src="https://hao1.qhimg.com/t01d07bd4ea1bb82a1c.png" alt="携程旅行">
              <span>携程旅行</span>
            </a>
          </li>
          <li>
            <a style="text-align:center;" href="https://www.douyin.com/?ug_source=360" alt="抖音短视频">抖音短视频</a>
          </li>
          <li>
            <a href="https://www.vip.com/?wq=1" alt="唯品会">唯&nbsp;品&nbsp;会</a>
          </li>
          <li>
            <a href="https://www.360kuai.com/mvideo?sign=360_6f06f061" alt="热门短视频" style="color: #f60 !important">热门短视频</a>
          </li>
          <li>
            <a class="favicon" href="https://kankan.eastday.com/?qid=01451" alt="头条">
              <img src="https://hao1.qhimg.com/t01f2b671978d27099f.jpg" alt="头条">
              <span>头条</span>
            </a>
            <span>•</span>
            <a href="https://mil.eastday.com/?qid=01502" alt="军情">军情</a>
          </li>
          <li>
            <a href="https://licai.360.cn/" alt="理财">理财</a>
            <span>•</span>
            <a href="https://www.eastmoney.com/" alt="东财">东财</a>
          </li>
          <li>
            <a href="https://ai.taobao.com/?pid=mm_26632323_6762370_25910879&clk1=5228019c5595357659b280cbd9955f0b" alt="爱淘宝" style="color:#f60 !important;">爱&nbsp;淘&nbsp;宝</a>
          </li>
          <li>
            <a href="https://zonghe.hao.360.cn/#cid=youlike?" alt="今日看点">今日看点</a>
          </li>
          <li>
            <a href="https://pages.tmall.com/wow/a/act/ju/dailygroup/2199/wupr?wh_pid=daily-222239&bid=0&spm=a21bo.2017.201859.2.516411d91wDfaX&ali_trackid=2:mm_26632323_6762370_32890637:1663916737_051_1666075510&union_lens=lensId:OPT@1612256136@0b1a25e7_ad17_17761f44e98_79f8@01;eventPageId:20150318020003696;recoveryid:1663916737_051_1666075510&clk1=6f67d56b8be0143902441c7cd56517da&bxsign=tbkNhANHkR9ipxr8icImSrAXFwibRFQh6hk8yfLaJ2oTYOBN5QRhjp9F6sIcQ293NF4NlVpizMtX2Tww8o5QJX7hI2cvyv5qBHUyqO91KPxMrvm9lrydUKFayIAnkGQWA5NhHPdijIqolhSJBhvv2WTSA" alt="聚划算">聚&nbsp;划&nbsp;算</a>
          </li>
          <li>
            <a class="favicon" href="https://yiwu.anjuke.com/?pi=navi-360-mz" alt="安居客">
              <img src="https://hao1.qhimg.com/t013df5f699b87c695b.jpg" alt="安居客">
              <span>安&nbsp;居&nbsp;客</span>
            </a>
          </li>
          <li>
            <a href="https://www.360kan.com/" alt="360影视">360影视</a>
          </li>
          <li>
            <a href="https://wan.360.cn/?src=hao-mz-top01&placeid=mz" alt="360游戏" style="color:#00ce31 !important">360游戏</a>
          </li>
          <li>
            <a href="https://hao.360.com/sub/2022sqjyzt.html" alt="考证提升">考证提升</a>
          </li>
          <li>
            <a href="https://www.n.cn/?ch=360dh" alt="N世界">N&nbsp;世&nbsp;界</a>
          </li>
          <li>
            <a href="https://g.360.cn/index.html" alt="游戏">游戏</a>
            <span>•</span>
            <a href="https://xiaoyouxi.360.cn/?src=youxi" alt="小游戏">小游戏</a>
          </li>
          <li>
            <a href="https://kankan.eastday.com/?qid=01365" alt="头条新闻">头条新闻</a>
          </li>
          <li>
            <a class="favicon" href="https://www.jd.com/?cu=true&utm_source=hao.360.com&utm_medium=tuiguang&utm_campaign=t_1000003625_360mz&utm_term=4c7b6772cc514f39a3f755c4245da8a5" alt="京东">
              <img src="//hao1.qhimg.com/t0193f3e475ca857ac8.png" alt="京东">
              <span>京&nbsp;东</span>
            </a>
          </li>
          <li>
            <a href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E5%BF%83%E7%90%86%E5%AD%A6%E4%B9%A6%E7%B1%8D&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A2556975756&lmsid=7f1d1df49baf0899" alt="心理学书籍">心理学书籍</a>
          </li>
          <li>
            <a href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E7%BD%91%E9%A1%B5%E6%B8%B8%E6%88%8F&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A4249379970&lmsid=7f1d1df49baf0899" alt="网页游戏">网页游戏</a>
          </li>
          <li>
            <a style="text-align:center;" href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E8%82%A1%E5%B8%82%E8%A1%8C%E6%83%85%E7%BD%91&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A4247948145&lmsid=7f1d1df49baf0899" alt="股市行情网">股市行情网</a>
          </li>
          <li>
            <a href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E4%BA%BA%E4%BA%8B%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A1976235555&lmsid=7f1d1df49baf0899" alt="人事管理系统">人事管理系统</a>
          </li>
          <li>
            <a style="text-align:center;" href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E7%81%AB%E9%BE%99%E4%BC%A0%E5%A5%87&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A862897532&lmsid=7f1d1df49baf0899" alt="火龙传奇">火龙传奇</a>
          </li>
          <li>
            <a style="text-align:center;" href="https://www.so.com/s?src=lm&ls=s57e4454d9f&q=%E6%8B%9B%E6%A0%87%E7%BD%91&lm_extend=ctype%3A20%7Clmbid%3A20%2C801%2C600%2C901%2C702%2C17%2C303%2C313%2C41%2C4%2C63%2C76%2C81%2C93%2C102%2C114%2C500%7Cjt%3A2%7Cmaxbid%3A4456713%2C4457016%2C4457029%2C4457064%2C4457073%2C4259843%2C4259862%2C4259880%2C4259905%2C4259921%2C4259926%2C4259940%2C4259944%2C4259960%2C4259968%2C4259971%2C4259985%2C4259993%2C4260102%2C4260114%2C4260119%2C4260132%2C4390929%2C4390930%2C4390945%2C4390978%2C4390996%2C4391012%2C4391026%2C4391040%2C4391058%2C4391073%2C4391075%2C4391077%2C4391079%2C4391177%2C4391313%2C4391318%2C4391457%2C4391489%2C4391504%2C4391509%2C4391537%2C4391553%2C4394625%2C4395026%2C4395280%2C4457057%2C4457081%2C4457083%2C4489473%7Csadspace%3A2360555%7Cadid%3A3285815405&lmsid=7f1d1df49baf0899" alt="招标网">招标网</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="font-news">
      <div class="font-news-gclearfix">
        <div class="font-news-ulone">
          <ul class="font-news-ul">
            <li>
              <a class="cur" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Ffawen.news.so.com%2Ff6802394ccf8ab4d7fa83faf711e9912&check=3e65304c63a7f033&sign=360_c109fef1&uid=&scene=49001&refer_scene=&tj_url=&ucheck=094947e61c6e194cdaabf2c4fdcff87a" alt="总书记的一周">
                时政微周刊丨总书记的一周（9月19日—25日）
              </a>
            </li>
            <li>
              <a class="cur" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F279f01cae434c1b36f323a9e18ab6b76&check=d5f88f64d74205f6&sign=360_c109fef1&uid=&scene=49001&refer_scene=&tj_url=&ucheck=527a3cff84b72a4e04d09adb01421a3d" alt="忠实履行代表职责">忠实履行代表职责&nbsp;</a>
              <span style="font-size:14px">|</span>
              <a class="cur" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F81b2f8e2654588a614f3e38edbacb165&check=450351b70fdd8026&scene=49001&refer_scene=&tj_url=&ucheck=2f188599662258a3f21ab60eff254200&sign=360_c109fef1&uid=" alt="锻造雄狮向复兴: 铸魂">&nbsp;锻造雄狮向复兴: 铸魂</a>
            </li>
            <li>
              <i class="incur"></i>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F19a90c37885050fa3f5f8c931e135660&check=2a6de6e39f347084&sign=360_c109fef1&uid=&scene=49001&refer_scene=&tj_url=&ucheck=e131d5283e6f4d071a6cec4388dd2183" alt="道路自信何以更加坚定">道路自信何以更加坚定&nbsp;</a>
              <span style="font-size:13px">|</span>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F04734a651e11afee4067fe6cbfa68667&check=dd6ffc05b2a65e47&tj_url=&ucheck=d938e1c72f2c67a060f615ede10d1cf2&sign=360_c109fef1&uid=&scene=49001&refer_scene=" alt="新农人的乡村振兴梦">&nbsp;新农人的乡村振兴梦</a>

            </li>
            <li>
              <i class="incur"></i>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F81c79502b38d561fa11c9424bbdf24ed&check=b5eff1c144e260a2&uid=&scene=49001&refer_scene=&tj_url=&ucheck=0f30e99f1246419b92abdccaa86dec19&sign=360_c109fef1" alt="奔向美好">奔向美好&nbsp;</a>
              <span style="font-size:13px">|</span>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2Fd54031d0e0e012f9359e6490c03871a4&check=33966fb9a7e701e3&uid=&scene=49001&refer_scene=&tj_url=&ucheck=9f853cdf66e07057662fd94e0c80999b&sign=360_c109fef1" alt="中国经济动能">&nbsp;中国经济动能&nbsp;</a>
              <span style="font-size:13px">|</span>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F2e8709871c21475a21c90bf3d2d3d279&check=4eb5646001c94e55&uid=&scene=49001&refer_scene=&tj_url=&ucheck=60ef817a4f25ed7f26feac49ce054958&sign=360_c109fef1" alt="稳岗资金直达市场主体">&nbsp;稳岗资金直达市场主体</a>
            </li>
            <li>
              <i class="incur"></i>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F5ff590ad8ecf59175196d04010e6d42a&check=8153f731c8db6dfa&tj_url=&ucheck=24587f1272444946968941166901cf13&sign=360_c109fef1&uid=&scene=49001&refer_scene=" alt="中国梦·大国工匠篇">中国梦·大国工匠篇&nbsp;</a>
              <span style="font-size:13px">|</span>
              <a class="curcle" href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2Fb2ac09fbc7d966358014bad3a21b3429&check=851eec922994bddd&uid=&scene=49001&refer_scene=&tj_url=&ucheck=241e47c09899523c716f644dba2d8f40&sign=360_c109fef1" alt="把旅客安全刻进骨子里">&nbsp;把旅客安全刻进骨子里</a>
            </li>
          </ul>
        </div>
        <div class="font-news-gclearfixtwo">
          <div class="font-news-ptitle">
            <p class="p-title">—&nbsp;&nbsp;要闻•热点&nbsp;&nbsp;—</p>
          </div>
          <div class="font-news-imagetitle">
            <a href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Ffawen.news.so.com%2F4a5fa1759e6cfddd0bd8082acfd383db&check=56b81e46e843bc8d&scene=49001&refer_scene=&tj_url=&ucheck=1699e3b6faa683696e25e08dcb3e4c37&sign=360_c109fef1&uid=" alt="欢庆国庆">
              <img src="https://p4.qhimg.com/dmfd/165_90_75/t01f5b64b082e1ed8d2.webp?size=848x520" alt="欢庆国庆">
              <span>“欢度国庆 喜迎党的二十大”主题展精彩亮相</span>
            </a>
            <a href="https://www.360kuai.com/pc/detail?check=e833300afee4dc52&url=http%3A%2F%2Ffawen.news.so.com%2Fdfc487991559b233a1310f2b4ab70035&uid=&scene=49001&refer_scene=&tj_url=&ucheck=cac0fa11b20e1381fb39e4a9d46a6520&sign=360_c109fef1" alt="贸易">
              <img src="https://p4.qhimg.com/dmfd/165_90_75/t01adedbc40e3331b0c.webp?size=886x514" alt="贸易">
              <span>&nbsp;&nbsp;每周9至14列从天津始发 中欧班列贸易“新丝路”</span>
            </a>
          </div>
        </div>
        <div class="font-news-gclearfixthird">
          <div class="font-news-gclearfixthird-divY">
            <img src="//hao2.qhimg.com/t01b9c19afa36282537.png" alt="新时代" style="width:336px;height:52px;">
          </div>
          <div class="font-news-gclearfixthird-divE">
            <li>
              <i class="incur"></i>
              <a href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Ffawen.news.so.com%2Fa7c9a9ede99ab19f05d9697c3559da20&check=4c3680fe965bc295&tj_url=&ucheck=5646d6b75637fa347a8c991b829b020e&sign=360_c109fef1&uid=&scene=49001&refer_scene=" alt="欢庆国庆">宝坻站全新亮相 京唐京滨计划年内通车</a>
            </li>
            <li>
              <i class="incur"></i>
              <a href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Fzm.news.so.com%2F77fec7aaa20c3c47ae3e1697474cc82d&check=c83c2f226a25efd0&tj_url=&ucheck=6e7c4b77a2f7fce695104cbd0f9b145f&sign=360_c109fef1&uid=&scene=49001&refer_scene=" alt="国家卫健委">国家卫健委：十年来群众就医体验显著改善</a>
            </li>
            <li>
              <i class="incur"></i>
              <a href="https://www.360kuai.com/pc/detail?url=http%3A%2F%2Ffawen.news.so.com%2F380555088ca14c19fad3851c4df0ee53&check=affe32c5313083d2&sign=360_c109fef1&uid=&scene=49001&refer_scene=&tj_url=&ucheck=abea5326f6566b48310f04fc7b106d6a" alt="辟谣工作">中央网信办深入开展网络辟谣标签工作</a>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="font-sites">
      <div class="font-left-sites">
        <div class="font-left-sites-left">
          <a href="javascript:" @click="colorchangeone();return false;" id="sites-lefta" class="sites-click">酷站</a>
        </div>
        <div class="font-left-sites-right">
          <a href="javascript:" @click="colorchangetwo();return false;" id="sites-righta" class="sites-noclick">我的</a>
        </div>
        <div class="font-left-sites-end" id="sites-enda">
          <div class="end-lift">
            <a class="end-lift-a" href="https://g.360.cn/index.html" alt="游戏">游戏</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.4399.com/?hao360a" alt="4399小游戏">4399小游戏</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://go.360.cn/" alt="旅游">旅游</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://go.360.cn/" alt="360旅游">360旅游</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://junshi.hao.360.cn/#cid=y1:military?" alt="军事">军事</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://mil.eastday.com/?qid=03649" alt="军事头条">军事头条</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/zhaopin_website_nav.html" alt="招聘">招聘</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.zhipin.com/sem/10.html?sid=sem&qudao=360dh&plan=360dh" alt="BOSS直聘">BOSS直聘</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://gouwu.360.com/" alt="购物">购物</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.jd.com/?cu=true&utm_source=hao.360.com&utm_medium=tuiguang&utm_campaign=t_1000003625_360kzgw&utm_term=3489d21bd2ab44578287f5743719ecea" alt="京东">京&nbsp;东</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://zonghe.hao.360.cn/#cid=youlike?" alt="新闻">新闻</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://kankan.eastday.com/?qid=360dh" alt="头条新闻">头条新闻</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://zonghe.hao.360.cn/#cid=youlike?" alt="资讯">资讯</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://news.junshi888.com/?360kz" alt="新闻头条">新闻头条</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/yinyue_website_nav.html" alt="音乐">音乐</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://mp3.junshi888.com/?360" alt="经典老歌">经典老歌</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://sports.hao.360.cn/#cid=y1:sport?" alt="体育">体育</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://sports.eastday.com/jrrd_ttkx/?qid=01459" alt="体坛资讯">体坛资讯</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://www.360kan.com/?from=kuzhan" alt="影视">影视</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://tv.sohu.com/" alt="搜狐视频">搜狐视频</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://shenghuo.hao.360.cn/#cid=y1:travel?" alt="生活">生活</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.xiachufang.com/" alt="下厨房">下厨房</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://licai.360.cn/" alt="财经">财经</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://mini.eastday.com/?qid=03989" alt="财经头条">财经头条</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/shouji_website_nav.html" alt="手机">手机</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://consumer.huawei.com/cn/phones/" alt="财经头条">华为手机</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/xiaoshuo_website_nav.html" alt="小说">小说</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="http://hao.360.com/sub/xiaoshuo_website_nav.html" alt="飞卢小说网">飞卢小说网</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://qiche.hao.360.cn/#cid=car?" alt="汽车">汽车</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.pcauto.com.cn/?ad=3474" alt="太平洋汽车">太平洋汽车</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://keji.hao.360.cn/#cid=y1:technology?" alt="电脑">电脑</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://www.apple.com.cn/mac/" alt="苹果电脑">苹果电脑</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://fangchan.hao.360.cn/#cid=fangchan?" alt="房产">房产</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://yiwu.anjuke.com/?pi=navi-360-fckz" alt="安居会"  style="color:#f60 !important;">安居会</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://edu.360.cn/" alt="教育">教育</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://edu.360.cn/caikuai/" alt="财会金融">财会金融</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/youxiang_website_nav.html" alt="邮箱">邮箱</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://mail.163.com/" alt="163邮箱">163邮箱</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/shequ_website_nav.html" alt="社区">社区</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="https://story.hao.360.cn/" alt="南瓜屋故事">南瓜屋故事</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="https://licai.360.cn/#bank" alt="银行">银行</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="http://www.icbc.com.cn/icbc/" alt="工商银行">工商银行</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
          <div class="end-lift">
            <a class="end-lift-a" href="http://hao.360.com/sub/nvxing_website_nav.html" alt="女性">女性</a>
            <div class="end-d"></div>
            <a class="end-lift-aa" href="http://www.mingxing123.com/#" alt="女性私房话">女性私房话</a>
            <div class="end-dd"></div>
          </div>
          <div class="lift-hr"></div>
        </div>
        <div class="font-left-sites-anotherend" id="sites-anotherenda">
          <ul class="site-list-ul">
            <li class="site-list-ul-li">
              <div class="site-list-empty">暂未添加网站</div>
            </li>
          </ul>
          <div class="div-add">
            <a href="" class="div-add-a">添加我的网址</a>
          </div>
        </div>
      </div>
      <div class="font-middle-sites">
        <div class="font-middle-sites-top">
          <a href="https://cube.360.com/index" target="_blank" alt="魔方">cube · 魔方</a>
          <ul class="font-middle-top-ul">
            <li id="li-one" @click="topcolorchangeone();">
              <div>我的</div>
            </li>
            <li id="li-two" @click="topcolorchangetwo();">
              <div>全部</div>
            </li>
            <li id="li-third" @click="topcolorchangethird();">
              <div>娱乐</div>
            </li>
            <li id="li-four" @click="topcolorchangefour();">
              <div>知识</div>
            </li>
            <li id="li-five" @click="topcolorchangefive();">
              <div>游戏</div>
            </li>
            <li id="li-six" @click="topcolorchangesix();"> 
              <div>故事</div>
            </li>
          </ul>
        </div>
        <div class="font-middle-sites-middle">
          <img class="follow-img" src="https://hao2.qhimg.com/t013185c4a0ebe8c9fc.png" alt="你还没有订阅喜爱的服务信息">
          <p class="follow-text">你还没有订阅喜爱的服务信息</p>
          <p class="follow-link">去发现精彩>></p>
        </div>
      </div>
      <div class="font-right-sites">
        <div class="font-right-sites-top"> 
          <ul class="font-right-sites-top-ul">
            <li class="right-sites-li">
              <a href="javascript:" id="attention" @click="topone();return false;" class="">关注</a>
            </li>
            <li>
              <a href="javascript:" id="found" @click="toptwo()" class="">发现</a>
            </li>
            <li>
              <a href="javascript:" id="entertainment" @click="topthird()">娱乐</a>
            </li>
            <li>
              <a href="https://g.360.cn/index.html?youce">游戏</a>
            </li>
            <li>
              <a href="https://junshi.hao.360.cn/#cid=y1:military?">军事</a>
            </li>
            <li>
              <a href="javascript:" id="sports" @click="topfour()">体育</a>
            </li>
            <li>
              <a href="javascript:" id="cars" @click="topfive()">汽车</a>
            </li>
            <li>
              <a href="javascript:" id="finance" @click="topsix()">财经</a>
            </li>
            <li>
              <a href="https://shenghuo.hao.360.cn/#cid=y1:travel?">生活</a>
            </li>
            <li>
              <a href="javascript:" id="property" @click="topseven()">房产</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        mounted: function() {
            this.hello();
        },
        methods: {
            hello() {
                // setTimeout("alert('10块钱看一次哈!')", 500);
            },
            colorchangeone() {
                document.getElementById("sites-righta").className = "sites-noclick"
                document.getElementById("sites-lefta").className = "sites-click"
                document.getElementById("sites-enda").style.display = "block"
                document.getElementById("sites-anotherenda").style.display = "none";

            },
            colorchangetwo() {
                document.getElementById("sites-righta").className = "sites-click"
                document.getElementById("sites-lefta").className = "sites-noclick"
                document.getElementById("sites-enda").style.display = "none";
                document.getElementById("sites-anotherenda").style.display = "block";
            },
            topcolorchangeone() {
                document.getElementById("li-one").style.color = "#fff"
                document.getElementById("li-one").style.backgroundColor = "#40cf6d"
                document.getElementById("li-two").style.color = "#333"
                document.getElementById("li-two").style.backgroundColor = "#fff"
                document.getElementById("li-third").style.color = "#333"
                document.getElementById("li-third").style.backgroundColor = "#fff"
                document.getElementById("li-four").style.color = "#333"
                document.getElementById("li-four").style.backgroundColor = "#fff"
                document.getElementById("li-five").style.color = "#333"
                document.getElementById("li-five").style.backgroundColor = "#fff"
                document.getElementById("li-six").style.color = "#333"
                document.getElementById("li-six").style.backgroundColor = "#fff"
            },
            topcolorchangetwo() {
                document.getElementById("li-one").style.color = "#333"
                document.getElementById("li-one").style.backgroundColor = "#fff"
                document.getElementById("li-two").style.color = "#fff"
                document.getElementById("li-two").style.backgroundColor = "#40cf6d"
                document.getElementById("li-third").style.color = "#333"
                document.getElementById("li-third").style.backgroundColor = "#fff"
                document.getElementById("li-four").style.color = "#333"
                document.getElementById("li-four").style.backgroundColor = "#fff"
                document.getElementById("li-five").style.color = "#333"
                document.getElementById("li-five").style.backgroundColor = "#fff"
                document.getElementById("li-six").style.color = "#333"
                document.getElementById("li-six").style.backgroundColor = "#fff"
            },
            topcolorchangethird() {
                document.getElementById("li-one").style.color = "#333"
                document.getElementById("li-one").style.backgroundColor = "#fff"
                document.getElementById("li-two").style.color = "#333"
                document.getElementById("li-two").style.backgroundColor = "#fff"
                document.getElementById("li-third").style.color = "#fff"
                document.getElementById("li-third").style.backgroundColor = "#40cf6d"
                document.getElementById("li-four").style.color = "#333"
                document.getElementById("li-four").style.backgroundColor = "#fff"
                document.getElementById("li-five").style.color = "#333"
                document.getElementById("li-five").style.backgroundColor = "#fff"
                document.getElementById("li-six").style.color = "#333"
                document.getElementById("li-six").style.backgroundColor = "#fff"
            },
            topcolorchangefour() {
                document.getElementById("li-one").style.color = "#333"
                document.getElementById("li-one").style.backgroundColor = "#fff"
                document.getElementById("li-two").style.color = "#333"
                document.getElementById("li-two").style.backgroundColor = "#fff"
                document.getElementById("li-third").style.color = "#333"
                document.getElementById("li-third").style.backgroundColor = "#fff"
                document.getElementById("li-four").style.color = "#fff"
                document.getElementById("li-four").style.backgroundColor = "#40cf6d"
                document.getElementById("li-five").style.color = "#333"
                document.getElementById("li-five").style.backgroundColor = "#fff"
                document.getElementById("li-six").style.color = "#333"
                document.getElementById("li-six").style.backgroundColor = "#fff"
            },
            topcolorchangefive() {
                document.getElementById("li-one").style.color = "#333"
                document.getElementById("li-one").style.backgroundColor = "#fff"
                document.getElementById("li-two").style.color = "#333"
                document.getElementById("li-two").style.backgroundColor = "#fff"
                document.getElementById("li-third").style.color = "#333"
                document.getElementById("li-third").style.backgroundColor = "#fff"
                document.getElementById("li-four").style.color = "#333"
                document.getElementById("li-four").style.backgroundColor = "#fff"
                document.getElementById("li-five").style.color = "#fff"
                document.getElementById("li-five").style.backgroundColor = "#40cf6d"
                document.getElementById("li-six").style.color = "#333"
                document.getElementById("li-six").style.backgroundColor = "#fff"
            },
            topcolorchangesix() {
                document.getElementById("li-one").style.color = "#333"
                document.getElementById("li-one").style.backgroundColor = "#fff"
                document.getElementById("li-two").style.color = "#333"
                document.getElementById("li-two").style.backgroundColor = "#fff"
                document.getElementById("li-third").style.color = "#333"
                document.getElementById("li-third").style.backgroundColor = "#fff"
                document.getElementById("li-four").style.color = "#333"
                document.getElementById("li-four").style.backgroundColor = "#fff"
                document.getElementById("li-five").style.color = "#333"
                document.getElementById("li-five").style.backgroundColor = "#fff"
                document.getElementById("li-six").style.color = "#fff"
                document.getElementById("li-six").style.backgroundColor = "#40cf6d"
            },
            topone() {
                document.getElementById("attention").className = "a-active"
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").setAttribute("class", "")
            },
            toptwo() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").className = "a-active"
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").setAttribute("class", "")
            },
            topthird() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").className = "a-active"
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").setAttribute("class", "")
            },
            topfour() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").className = "a-active"
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").setAttribute("class", "")
            },
            topfive() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").className = "a-active"
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").setAttribute("class", "")
            },
            topsix() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").className = "a-active"
                document.getElementById("property").setAttribute("class", "")
            },
            topseven() {
                document.getElementById("attention").setAttribute("class", "")
                document.getElementById("found").setAttribute("class", "")
                document.getElementById("entertainment").setAttribute("class", "")
                document.getElementById("sports").setAttribute("class", "")
                document.getElementById("cars").setAttribute("class", "")
                document.getElementById("finance").setAttribute("class", "")
                document.getElementById("property").className = "a-active"
            }
        }
    }
</script>

<style scoped>
    @import url(../assets/css/liulan.css);
</style>