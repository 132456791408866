<template>
  <div class="classstyle">
    <div class="static" v-bind:class={active:isActive}>
      <div class="div1">
        <input type="text" name="" id="">
      </div>
      <div class="div2">
        <input type="text" name="" id="">
      </div>
    </div>
    <!-- 用户: zwzz 欢迎登录 -->
  </div>
</template>

<script>
    export default {
        data: {
            idActive: false,
        },
        methods: {

        }
    }
</script>

<style scoped>
    .static {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
        background: url(http://www.51pptmoban.com/d/file/2015/07/04/b6eba56d95c6b5b7e237b8035b6c687e.jpg) no-repeat;
        background-size: cover;
    }
    
    .div1,
    .div2 {
        width: 300px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background: hsl(55, 86%, 77%, 0.25);
        /* background: rgba(255, 255, 255, 0.5); 白色*/
        backdrop-filter: blur(1px);
        /* box-shadow: 0 0 8px 0 #eee; */
        margin-bottom: 20px;
    }
    
    .div1 input,
    .div2 input {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 10px;
        margin: auto;
        outline: none;
        background: hsl(55, 86%, 77%, 0);
        border: 1px solid rgba(255, 255, 255, 0);
        font-size: 2em;
        color: #fff;
    }
</style>